import React from 'react';
import PropTypes from 'prop-types';
import NextHead from 'next/head';
import { withRouter } from 'next/router';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
const SITE_URL = publicRuntimeConfig.SITE_URL;
const CLIENT_INSTANCE = publicRuntimeConfig.CLIENT_INSTANCE;

function Head({
  title,
  description,
  ogTitle,
  ogImage,
  ogImageHeight,
  ogImageWidth,
  router,
}) {
  const { asPath } = router;

  const OG_IMAGE =
    CLIENT_INSTANCE === 'unpasteurized'
      ? `${SITE_URL}/static/img/og/unpasteurized_og.png`
      : ogImage;

  return (
    <NextHead>
      {title && <title key="title">{title}</title>}
      {description && (
        <meta key="description" name="description" content={description} />
      )}
      {title && (
        <meta key="twitterTitle" name="twitter:title" content={title} />
      )}
      {description && (
        <meta
          key="twitterDescription"
          name="twitter:description"
          content={description}
        />
      )}
      {OG_IMAGE && (
        <meta key="twitterImage" name="twitter:image" content={OG_IMAGE} />
      )}
      <meta
        key="twitterUrl"
        name="twitter:url"
        content={`${SITE_URL}${asPath}`}
      />
      {(title || ogTitle) && (
        <meta key="ogTitle" property="og:title" content={ogTitle || title} />
      )}
      {description && (
        <meta
          key="ogDescription"
          property="og:description"
          content={description}
        />
      )}

      {ogImageWidth && (
        <meta
          key="ogImageWidth"
          property="og:image:width"
          content={ogImageWidth}
        />
      )}
      {ogImageWidth && (
        <meta
          key="ogImageHeight"
          property="og:image:height"
          content={ogImageHeight}
        />
      )}
      {OG_IMAGE && (
        <meta key="ogImage" property="og:image" content={OG_IMAGE} />
      )}
      <meta key="ogUrl" property="og:url" content={`${SITE_URL}${asPath}`} />
    </NextHead>
  );
}

Head.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  ogTitle: PropTypes.string,
  ogImage: PropTypes.string,
  router: PropTypes.object.isRequired,
  ogImageWidth: PropTypes.number,
  ogImageHeight: PropTypes.number,
};

Head.defaultProps = {
  title: undefined,
  ogTitle: undefined,
  description: undefined,
  ogImage: undefined,
  ogImageWidth: undefined,
  ogImageHeight: undefined,
};

export default withRouter(Head);
