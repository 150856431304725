import React from 'react';
import PropTypes from 'prop-types';
import colors from 'styles/config.colors.css';
import css from './InteractiveMap.css';

function InteractiveMap({
  onFireToggle,
  onWaterToggle,
  onWindToggle,
  onNeutralToggle,
}) {
  return (
    <svg className={css.interactiveMap} viewBox="0 0 1068 573">
      <g stroke={colors.colorBlack} fill="none" fillRule="evenodd">
        <path
          className={`${css.piece} ${css.fire}`}
          onMouseOver={onFireToggle}
          onMouseLeave={onFireToggle}
          d="M501.564 460.182h-43.357l-35.377 22.077v26.987l-15.975 10.268v33.09l29.67 18.254h29.67l29.662-15.974h52.492l13.687-9.128h82.155l23.217-13.844h28.485l69.25-40.92v-20.543l-27.3-15.975h-79.957l-35.377-22.822H576.87l-18.255 12.555h-30.81z"
        />
        <path
          className={`${css.piece} ${css.water}`}
          onMouseOver={onWaterToggle}
          onMouseLeave={onWaterToggle}
          d="M582.549 215.633l16.876-11.087-19.23-12.972 90.335-55.45h26.384l49.013-29.82 35.504 21.318v24.149l58.587 36.673h78.257l29.514 18.337h29.96l50.093 29.514v40.25l38.007 21.906v27.285l-20.125 11.624h-47.403l-62.156-37.568h-44.274l-31.301 18.337v35.78l68.415 39.348v33.991l-50.98 30.408h-92.563l-46.957-29.514h-64.398l-54.11-33.544v-14.947l-123.872-74.68v-25.848h21.37l25.892 15.43 39.162-25.511v-25.52l22.733-13.8z"
        />
        <path
          className={`${css.piece} ${css.wind}`}
          onMouseOver={onWindToggle}
          onMouseLeave={onWindToggle}
          d="M455.815 253.856l-46.345-28.313H306.61l-94.226-56.754H61.114L1.88 204.039l37.173 23.583-22.696 13.15v14.888l45.22 26.644v11.773l19.38 11.296v18.463l36.458 23.068h77.982l28.15 15.692v11.072l41.532 25.84v31.384l53.99 31.838h60.45l38.76-23.076v-29.99l44.303-23.538h53.066l25.378 22.614h57.678l21.295-14.775V379.65l-123.873-74.68v-26.482"
        />
        <path
          className={`${css.piece} ${css.neutral}`}
          onMouseOver={onNeutralToggle}
          onMouseLeave={onNeutralToggle}
          d="M745.793 106.17l-48.178-28.693h-63.766l-17.882-9.984V42.547l-48.38-29.32h-70.143l-18.71-11.854h-60.3L378.92 24.94v20.095l22.457 13.725v26.615l-30.563 18.3H293.95l-22.941 14.35h-64.876L166.21 94.316H54.96l-53.587 32.68v77.282l59.742-35.49h151.27l94.224 56.755H409.47l46.554 28.202 64.22-38.112h62.305l16.876-11.087-19.23-12.972 90.335-55.45h26.384z"
        />
      </g>
      <g
        stroke={colors.colorBlack}
        fill={colors.colorYellow200}
        fillRule="evenodd"
      >
        <path d="M456.024 253.745l64.22-38.112h62.305l22.733 14.09-22.733 13.799v25.52l-39.162 25.512-25.892-15.431h-21.28z" />
      </g>
    </svg>
  );
}

InteractiveMap.propTypes = {
  onFireToggle: PropTypes.func.isRequired,
  onWaterToggle: PropTypes.func.isRequired,
  onWindToggle: PropTypes.func.isRequired,
  onNeutralToggle: PropTypes.func.isRequired,
};

export default InteractiveMap;
