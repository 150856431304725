import React from 'react';
import Hero from './Hero.js';
import Tournament from './Tournament.js';
import Dueling from './Dueling.js';
import Prize from './Prize.js';
import css from './LandingPage.css';

function LandingPage() {
  return (
    <div data-cy="page-landing">
      <Hero />
      <div className={css.melt} />
      <Tournament />
      <Dueling />
      <Prize />
    </div>
  );
}

export default LandingPage;
