export function AnimationSelect(name) {
  const ANIMATIONS = {
    body: () => import('./animations/body.json'),
    hat: () => import('./animations/hat.json'),
    hands: () => import('./animations/hands.json'),
    fx: () => import('./animations/fx.json'),
    fireFlag: () => import('./animations/fireFlag.json'),
    firePrep: () => import('./animations/firePrep.json'),
    waterFlag: () => import('./animations/waterFlag.json'),
    waterPrep: () => import('./animations/waterPrep.json'),
    windFlag: () => import('./animations/windFlag.json'),
    neutralFlag: () => import('./animations/neutralFlag.json'),
    udder: () => import('./animations/udder.json'),
    sparkles: () => import('./animations/sparkles.json'),
    summon: () => import('./animations/summon.json'),
    summonInProgress: () => import('./animations/summon-in-progress.json'),
  };

  const animation = ANIMATIONS[name];

  return animation();
}
