import React from 'react';
import PropTypes from 'prop-types';
import css from './PrizePool.css';

function PrizePool({ isHeader }) {
  const imageName = isHeader ? 'endTournament1.svg' : 'coin.svg';

  return (
    <div className={css.prizePool}>
      <img
        className={css.image}
        src={`/static/img/prizePool/${imageName}`}
        alt="The Big Cheeze"
      />
    </div>
  );
}

PrizePool.propTypes = {
  isHeader: PropTypes.bool,
};

PrizePool.defaultProps = {
  isHeader: false,
};

export default PrizePool;
