import React, { useState } from 'react';
import Button from 'components/Button';
import Container from 'components/Container';
import Map from 'components/Map';
import css from './Tournament.css';

function Tournament() {
  const [isExpanded, onExpand] = useState(false);

  return (
    <div className={css.tournament}>
      <Container padding="gutter15">
        <h1 className={css.title}>The Tournament</h1>
        <p className={css.light}>
          The Cheeze Wizard tournament consists of a series of duels that
          culminate in the most powerful Wizard being crowned{' '}
          <strong>The Big Cheeze</strong>.
        </p>
        {!isExpanded && (
          <Button className={css.textLink} onClick={() => onExpand(true)}>
            Learn more
          </Button>
        )}
        {isExpanded && (
          <div>
            <p className={css.light}>
              Originally created to settle online disagreements between the four
              lands, the inaugural Cheeze Wizards tournament melted a nation,
              boiled the oceans, and cost countless lives. Overall, it was
              considered an improvement over arguing on social media.
            </p>
            <p className={css.light}>
              Today, the tournament carries the proud tradition of using magical
              forces beyond mortal understanding to settle petty disagreements.
            </p>
          </div>
        )}
        <div className={css.map}>
          <Map />
        </div>
      </Container>
    </div>
  );
}

export default Tournament;
