import React from 'react';
import Button from 'components/Button';
import Container from 'components/Container';
import PrizePool from 'components/PrizePool';
import css from './Prize.css';

function Prize() {
  return (
    <div className={css.prize}>
      <Container>
        <h1 className={css.title}>
          B1ackKett1e becomes the Big Cheeze and wins the Tournament
        </h1>
        <PrizePool />
        <div>
          <Button
            theme={{
              appearance: 'blackWhite',
              display: 'inlineBlock',
              size: 'big',
            }}
            href="https://twitter.com/chzwzrds"
          >
            Follow us on Twitter
          </Button>
        </div>
      </Container>
    </div>
  );
}

export default Prize;
