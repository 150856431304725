import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import affinityTypes from 'constants/affinityTypes';
import AnimationPlayer from 'components/AnimationPlayer';
import Card from './Card.js';
import css from './Flag.css';

const AFFINITY_ANIMATION_NAMES = {
  [affinityTypes.FIRE]: 'fireFlag',
  [affinityTypes.WATER]: 'waterFlag',
  [affinityTypes.WIND]: 'windFlag',
  [affinityTypes.NEUTRAL]: 'neutralFlag',
};

const AFFINITY_CLASS_NAMES = {
  [affinityTypes.FIRE]: 'fire',
  [affinityTypes.WATER]: 'water',
  [affinityTypes.WIND]: 'wind',
  [affinityTypes.NEUTRAL]: 'neutral',
};

function Flag({ affinity, isDescriptionVisible, quantity }) {
  const affinityAnimationName = AFFINITY_ANIMATION_NAMES[affinity];
  const affinityClassName = AFFINITY_CLASS_NAMES[affinity];

  return (
    <div className={`${css.flag} ${css[affinityClassName]}`}>
      <div
        className={cx(css.description, {
          [css.visible]: isDescriptionVisible,
        })}
      >
        <Card affinity={affinity} />
      </div>
      <AnimationPlayer
        className={css.flagImage}
        name={affinityAnimationName}
        autoplay
        loop
      />
      <p className={css.label}>{affinity} Wizards</p>
      <p className={css.quantity}>{quantity}</p>
    </div>
  );
}

Flag.propTypes = {
  affinity: PropTypes.oneOf(Object.keys(affinityTypes)).isRequired,
  isDescriptionVisible: PropTypes.bool,
  quantity: PropTypes.number,
};

Flag.defaultProps = {
  isDescriptionVisible: false,
  quantity: null,
};

export default Flag;
