import React, { useState } from 'react';
import AnimationPlayer from 'components/AnimationPlayer';
import Container from 'components/Container';
import Button from 'components/Button';
import PrizePool from 'components/PrizePool';
import css from './Hero.css';

function Hero() {
  const [isExpanded, onExpand] = useState(false);

  return (
    <div className={css.heroWrapper}>
      <div className={css.hero}>
        {isExpanded && (
          <div className={css.trailer}>
            <iframe
              className={css.embed}
              src="https://www.youtube.com/embed/l2IdF4USJDI"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
            <Button
              theme={{ appearance: 'blackWhite', display: 'inlineBlock' }}
              onClick={() => onExpand(false)}
            >
              Close
            </Button>
          </div>
        )}
        <AnimationPlayer
          className={css.udder}
          autoplay
          loop
          name="udder"
          renderer="canvas"
        />
        <div className={css.wizards}>
          <AnimationPlayer
            className={css.leftWizard}
            renderer="canvas"
            autoplay
            loop
            name="firePrep"
          />
          <AnimationPlayer
            className={css.rightWizard}
            renderer="canvas"
            autoplay
            loop
            name="waterPrep"
          />
        </div>
        <Container className={css.prize}>
          <PrizePool isHeader={true} />
          <div>
            <Button
              theme={{
                appearance: 'blackWhite',
                display: 'inlineBlock',
                size: 'big',
              }}
              href="https://twitter.com/chzwzrds"
            >
              Follow us on Twitter
            </Button>
          </div>
          <div>
            <Button onClick={() => onExpand(true)} className={css.textLink}>
              Play Trailer
            </Button>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default Hero;
