import React, { Component, Fragment } from 'react';
import Head from 'components/Head';
import LandingPage from 'components/LandingPage';

class Index extends Component {
  render() {
    return (
      <Fragment>
        <Head
          title="Cheeze Wizards: A blockchain battle royale with cheese"
          description="The Cheeze Wizards Tournament is a test of skill, power, and cheese. Summon Wizards to compete in the tournament, duel your friends, and win the Big Cheeze!"
        />
        <LandingPage />
      </Fragment>
    );
  }
}

Index.propTypes = {};

Index.defaultProps = {};

export default Index;
