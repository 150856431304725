import React, { useState } from 'react';
import affinityTypes from 'constants/affinityTypes';
import InteractiveMap from './InteractiveMap.js';
import Flag from './Flag.js';
import Card from './Card.js';
import css from './Map.css';

function Map() {
  const [isFireToggled, onFireToggle] = useState(false);
  const [isWaterToggled, onWaterToggle] = useState(false);
  const [isWindToggled, onWindToggle] = useState(false);
  const [isNeutralToggled, onNeutralToggle] = useState(false);

  return (
    <div>
      <div className={`${css.mapWrapper} ${css.mobile}`}>
        <img
          className={css.staticMap}
          src="/static/img/map/mapMini.svg"
          alt="Map"
        />
        <div className={css.cardGrid}>
          <Card affinity={affinityTypes.FIRE} />
          <Card affinity={affinityTypes.WATER} />
          <Card affinity={affinityTypes.WIND} />
          <Card affinity={affinityTypes.NEUTRAL} />
        </div>
      </div>
      <div className={`${css.mapWrapper} ${css.desktop}`}>
        <img
          className={css.tower}
          src="/static/img/map/tower.svg"
          alt="Tower"
        />
        <Flag
          affinity={affinityTypes.NEUTRAL}
          isDescriptionVisible={isNeutralToggled}
          quantity={3620}
        />
        <Flag
          affinity={affinityTypes.WIND}
          isDescriptionVisible={isWindToggled}
          quantity={795}
        />
        <Flag
          affinity={affinityTypes.WATER}
          isDescriptionVisible={isWaterToggled}
          quantity={847}
        />
        <Flag
          affinity={affinityTypes.FIRE}
          isDescriptionVisible={isFireToggled}
          quantity={845}
        />
        <InteractiveMap
          onFireToggle={() => onFireToggle(!isFireToggled)}
          onWaterToggle={() => onWaterToggle(!isWaterToggled)}
          onWindToggle={() => onWindToggle(!isWindToggled)}
          onNeutralToggle={() => onNeutralToggle(!isNeutralToggled)}
        />
      </div>
    </div>
  );
}

export default Map;
