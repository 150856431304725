import React from 'react';
import PropTypes from 'prop-types';
import * as pages from 'constants/pages';
import affinityTypes from 'constants/affinityTypes';
import Button from 'components/Button';
import css from './Card.css';

const WIZARD_IMG_NAMES = {
  [affinityTypes.FIRE]: 'firePortrait.svg',
  [affinityTypes.WATER]: 'waterPortrait.svg',
  [affinityTypes.WIND]: 'windPortrait.svg',
  [affinityTypes.NEUTRAL]: 'neutralPortrait.svg',
};

function Card({ affinity }) {
  const wizardImgName = WIZARD_IMG_NAMES[affinity];

  return (
    <Button
      href={pages.SUMMON}
      className={css.card}
      contentClass={css.contentCard}
      trackingProperties={{
        buttonId: `map_card${affinity}`,
      }}
    >
      <img
        className={css.wizard}
        src={`/static/img/map/${wizardImgName}`}
        alt="Wizard"
      />
      <div className={css.info}>
        {affinity === affinityTypes.FIRE && (
          <p>
            Known for being hot-headed,{' '}
            <span className={css.strong}>Fire Wizards</span> can use all the
            elements, but their fire spells are extra strong against wind and
            extra weak to water. They are poor role models due to their smoking
            habit.
          </p>
        )}
        {affinity === affinityTypes.WATER && (
          <p>
            Mocked for being wishy-washy,{' '}
            <span className={css.strong}> Water Wizards</span> can use all the
            elements, but their water spells are extra strong against fire and
            extra weak to wind. They are inexplicably moist all of the time.
          </p>
        )}
        {affinity === affinityTypes.WIND && (
          <p>
            Recognized as a nation of blow-hards,{' '}
            <span className={css.strong}>Wind Wizards</span> can use all the
            elements, but their wind spells are extra strong against water and
            extra weak to fire. They are known to be full of hot air.
          </p>
        )}
        {affinity === affinityTypes.NEUTRAL && (
          <p>
            Impolitely described as “basic,”{' '}
            <span className={css.strong}>Neutral Wizards</span> can use all the
            elements. They have no strengths, no weaknesses, and no strong
            feelings one way or another.
          </p>
        )}
      </div>
    </Button>
  );
}

Card.propTypes = {
  affinity: PropTypes.oneOf(Object.keys(affinityTypes)).isRequired,
};

export default Card;
