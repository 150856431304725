import React from 'react';
import Container from 'components/Container';
import css from './Dueling.css';

function Dueling() {
  return (
    <div className={css.dueling}>
      <Container>
        <img
          className={`${css.title} ${css.square}`}
          src="/static/img/landingPage/duelTitleSquare.svg"
          alt="The Duel"
        />
        <img
          className={`${css.title} ${css.rectangle}`}
          src="/static/img/landingPage/duelTitleRect.svg"
          alt="The Duel"
        />
        <div className={css.steps}>
          <div className={css.step}>Challenge or be challenged to duel!</div>
          <div className={css.step}>
            In a duel, both Wizards forecast five spells from the three
            elements.
          </div>
          <div className={css.step}>
            Successfully cast more spells than your opponent to win the duel.
          </div>
          <div className={css.step}>
            Win duels to increase your Wizard’s power
          </div>
          <div className={css.step}>
            The Wizard with the most power at the end of the tournament becomes
            the Big Cheeze!
          </div>
        </div>
      </Container>
      <div className={css.action} />
    </div>
  );
}

export default Dueling;
