import React, { Component } from 'react';
import PropTypes from 'prop-types';
import lottie from 'lottie-web';
import { detect } from 'detect-browser';
import cx from 'classnames';
import isTestMode from 'lib/isTestMode';
import { AnimationSelect } from 'components/AnimationPlayer/AnimationSelect';
import css from './AnimationPlayer.css';

class AnimationPlayer extends Component {
  constructor(props) {
    super(props);
    this.animationElement = React.createRef();
  }

  componentDidMount() {
    this.loadAnimation();
  }

  componentWillUnmount() {
    this.animation.destroy();
  }

  async loadAnimation() {
    const {
      autoplay,
      loop,
      name,
      unlimitedFPS,
      aspectRatioType,
      renderer,
    } = this.props;

    // currently we block bodymovin for firefox
    // only way to support firefox is to not have masks at all in the animations
    // https://github.com/airbnb/lottie-web/issues/1214
    // https://github.com/airbnb/lottie-web/issues/148
    const browser = detect();
    const isFirefox = browser.name === 'firefox';
    const canAutoPlay = !isTestMode() && !isFirefox && autoplay;
    const selectedAnimation = await AnimationSelect(name);

    const config = {
      animationData: selectedAnimation,
      autoplay: canAutoPlay,
      container: this.animationElement.current,
      loop: loop,
      renderer: renderer,
      rendererSettings: {
        preserveAspectRatio: aspectRatioType,
        progressiveLoad: true, // Boolean, only svg renderer, loads dom elements when needed. Might speed up initialization for large number of elements.
      },
    };
    this.animation = lottie.loadAnimation(config);
    this.animation.setSubframe(unlimitedFPS);
  }

  render() {
    const { className } = this.props;

    return (
      <div
        className={cx(css.animationPlayer, className)}
        ref={this.animationElement}
      />
    );
  }
}

AnimationPlayer.propTypes = {
  name: PropTypes.string.isRequired,
  autoplay: PropTypes.bool,
  className: PropTypes.string,
  loop: PropTypes.bool,
  unlimitedFPS: PropTypes.bool,
  aspectRatioType: PropTypes.string,
  renderer: PropTypes.oneOf(['svg', 'canvas']),
};

AnimationPlayer.defaultProps = {
  autoplay: false,
  className: undefined,
  loop: false,
  unlimitedFPS: false,
  aspectRatioType: undefined,
  renderer: 'svg',
};

export default AnimationPlayer;
